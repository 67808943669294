<template>
    <div class="bo">
        <div class="top">
            <div>
                <img src="../../assets/img/Slice56@2x.png">
                <span>接送管理</span>
            </div>
            <div>
                <router-link to="/pickup_add">
                    <img src="../../assets/img/Slice57@2x.png">
                </router-link>
            </div>
        </div>
        <div class="main">
            <router-link to="/s_details">
                <div class="warp" id="stu">
                    <div class="head">
                        <img v-if="photo" :src="oss + photo" @error="ere()">
                        <img v-else src="@/assets/img/nohead.png">
                    </div>
                    <div class="cont">
                        <div>{{ this.user_name }}</div>
                    </div>
                    <div class="go"> <img src="../../assets/img/bt.png" style="margin-right: -3.5vw;width: 15vw;height: 15vw;"> </div>
                </div>
            </router-link>


            <div class="warp" id="par" v-for="(item, index) in pick_list" :key="index">
                <router-link :to="{path: '/pickup_details', query:{ id: item.id }}">
                    <div class="head">
                        <img v-if="item.face" :src="item.face" @error="error()">
                        <img v-else src="../../assets/img/nohead.png">
                    </div>
                    <div class="cont" id="pcont">
                        <div class="name">{{ item.user_name }}</div>
                        <div class="time">
                            <span>最近使用时间：</span><span>{{ item.last_use_date }}</span>
                        </div>
                        <div class="msg" v-if="item.is_use == false">
                            <img src="../../assets/img/2.png" alt="">
                            <span>此接送人已30天没有接送记录</span>
                        </div>
                    </div>
                    <div class="go"> <img src="../../assets/img/jiantou.png"> </div>
                    <div class="tag">有效期：长期</div>
                </router-link>
                <!-- <div class="tag2">有效期 : 2022-11-16</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getlist, edit_info } from '@/api/pickup/list.js'
import {getUserId, getUserName} from "../../utils/user";

export default {
    data() {
        return {
            oss: process.env.VUE_APP_OSS_URL,
            pick_list: [],
            id: '',
            photo: '',
            user_name: ''
        };
    },
    created() {
      this.id = getUserId();
      this.user_name = getUserName();
      this.getlist()
      this.getinfo()
    },
    methods: {

        getinfo(){
            edit_info(this.id).then(res => {
                this.photo = res.data.face
            })
        },

        getlist() {
            getlist().then(res => {
                this.pick_list = res.data
            })
        },

        error() {
            this.photo = ''
        },
        ere(){
            this.photo = ''
        }
    }
};
</script>

<style lang="less" scoped src="./css/list.less"></style>
